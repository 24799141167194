<template>
  <el-container class="gps-body" style="padding: 5px; background: #f2f2f2">
    <el-aside class="gps-sys-left">
      <el-menu
        active-text-color="#ffd04b"
        background-color="#fff"
        class="el-menu-vertical-demo"
        :default-active="default_active_id"
        text-color="#000"
        @open="handleOpen"
        @close="handleClose"
      >
        <el-menu-item
          :index="it.id + ''"
          v-for="it in menus"
          :key="it.id"
          @click="go_url(it)"
        >
          <el-icon><location v-if="false" /></el-icon>
          <span>{{ it.name }}</span>
        </el-menu-item>
      </el-menu>
      <!-- <router-link to="/syssets/cars">车辆管理</router-link>
      <router-link to="/syssets/cargroup">{{ $t("map.chezuguanli")}}</router-link> -->
    </el-aside>
    <el-main height="">
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from "@element-plus/icons-vue";
import { reactive, getCurrentInstance, ref, watch, nextTick } from "vue";
import { useRouter } from "vue-router";
export default {
  components: { Location },
  setup(props) {
    let { proxy } = getCurrentInstance();
    const default_active_id = ref("17");
    const router = useRouter();
    const go_url = (options) => {
      if (options.rName) {
        router.push({ name: options.rName });
      }
    };
    const handleOpen = (key, keyPath) => {
      console.log(key, keyPath);
    };
    const handleClose = (key, keyPath) => {
      console.log(key, keyPath);
    };
    const menus = ref([
      // { id: 1, name: "车辆管理", icon: "", rName: "syssets-cars" },
      // { id: 2, name: "车组管理", icon: "", rName: "syssets-cargroup" },
      // { id: 3, name: "角色管理", icon: "", rName: "syssets-roles" },
      // { id: 4, name: "公司管理", icon: "", rName: "syssets-companys" },
      // { id: 5, name: "用户管理", icon: "", rName: "syssets-users" },
      // { id: 6, name: "司机管理", icon: "", rName: "syssets-drivers" },
      // { id: 7, name: "保险公司", icon: "", rName: "syssets-insurances" },
      // { id: 8, name: "SIM卡管理", icon: "", rName: "syssets-sims" },
      // { id: 9, name: "区域管理", icon: "", rName: "syssets-maprect" },
      // { id: 10, name: "规则管理", icon: "", rName: "syssets-rules" },
      // { id: 11, name: "系统配置", icon: "", rName: "syssets-xtpz" },
      // { id: 12, name: "服务器配置", icon: "", rName: "syssets-fwqpz" },
    ]);

    proxy.$api.menu_get_menu_list_by_role({ pid: 5 }).then((res) => {
      // console.log(res.data);
      let ay = [];
      res.data.list.forEach((it) => {
        ay.push({
          id: it.id,
          name: it.mTitle,
          icon: it.mIcon,
          rName: it.mRName,
        });
      });
      menus.value = ay;
    });

    watch(
      () => router.currentRoute.value.path,
      (newValue, oldValue) => {
        // console.log("watch", newValue);
        if (newValue.indexOf("/cars") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "17";
          });
        }
        if (newValue.indexOf("/cargroup") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "18";
          });
        }
        if (newValue.indexOf("/roles") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "19";
          });
        }
        if (newValue.indexOf("/companys") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "20";
          });
        }
        if (newValue.indexOf("/users") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "21";
          });
        }
        if (newValue.indexOf("/drivers") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "22";
          });
        }
        if (newValue.indexOf("/insurances") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "23";
          });
        }
        if (newValue.indexOf("/sims") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "24";
          });
        }
        if (newValue.indexOf("/maprect") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "25";
          });
        }
        if (newValue.indexOf("/rules") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "26";
          });
        }
        if (newValue.indexOf("/xtpz") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "27";
          });
        }
        if (newValue.indexOf("/fwqpz") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "28";
          });
        }
        //
      },
      { immediate: true }
    );

    return { handleOpen, handleClose, menus, go_url, default_active_id };
  },
};
</script>

<style lang="scss" scoped>
.gps-body {
  height: 100%;
  .gps-sys-left {
    width: 199px;
    background: #fff;
    border: 1px solid #ccc;
    margin-right: 10px;
  }
  // .left {
  //   box-sizing: border-box;
  //   padding: 10px 0 0 10px;
  // }
}
::v-deep(.el-menu) {
  border-right: none;
}

::v-deep .gps-cars {
}
//::v-deep .gps-box{background:#fff;border:1px solid #ccc;}
::v-deep .gps-search {
  padding: 8px 10px 0px 10px;
  border-radius: 0px;
  height: 50px;
  background: #fff;
  border: 1px solid #ccc;
  border-bottom: none;
}
::v-deep .gps-body {
}
::v-deep .el-button--success {
  background: #2485e7 !important;
  border: none;
}

::v-deep .is-round {
  background: #03b356;
  border: none;
  color: #fff;
  height: 30px !important;
}

::v-deep .cell .is-round {
  background: #03b356;
  border: none;
  color: #fff;
  height: 25px !important;
}
::v-deep .el-button--warning {
  background: #ff960c !important;
}
::v-deep .el-button--danger {
  background: #f9543e !important;
}
::v-deep .gps-search .gps-item:last-child .el-button--warning {
  background: #24aaf1 !important;
}
::v-deep .gps-search .gps-item:nth-child(8) .is-round {
  background: #dc5bc7 !important;
}
::v-deep .el-select .el-input__inner {
  height: 30px !important;
}
::v-deep .gps-body .is-round {
  background: #f1f6fc !important;
  border: 1px solid #2485e7;
  color: #1975d1;
}
::v-deep .gps-body .el-button--danger {
  background: #fcf7f6 !important;
  border: 1px solid #f9543e;
  color: #dc3a24;
}
::v-deep .el-input__inner {
  height: 30px !important;
}
::v-deep .el-range-editor--small.el-input__wrapper {
  height: 32px !important;
}
::v-deep .el-table--fit {
  border: 1px solid #e0dede;
}
::v-deep .el-menu li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  height: 45px;
  line-height: 45px;
}
::v-deep .el-menu li:nth-child(1) {
  background: url(../../assets/meun/d1.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(2) {
  background: url(../../assets/meun/d2.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(3) {
  background: url(../../assets/meun/d3.png) no-repeat 20px center;
}

::v-deep .el-menu li:nth-child(4) {
  background: url(../../assets/meun/d4.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(5) {
  background: url(../../assets/meun/d5.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(6) {
  background: url(../../assets/meun/d6.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(7) {
  background: url(../../assets/meun/d7.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(8) {
  background: url(../../assets/meun/d8.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(9) {
  background: url(../../assets/meun/d9.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(10) {
  background: url(../../assets/meun/d10.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(11) {
  background: url(../../assets/meun/d11.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(12) {
  background: url(../../assets/meun/d12.png) no-repeat 20px center;
}
::v-deep .el-menu-item.is-active {
  color: #2c78bf;
  //background-color:#666 !important;
}
::v-deep .el-menu {
  padding-top: 5px;
}
::v-deep .el-input--small .el-input__wrapper {
  margin-right: 10px;
}
::v-deep .gps-page {
  background: #fff;
  padding: 0px 10px 0px 10px;
  border: 1px solid #ccc;
  border-top: none;
}
::v-deep .dialog-footer .el-button--small {
  width: 100px;
  height: 35px;
  line-height: 35px;
}
::v-deep .el-table tr:nth-child(even) {
  background: #f9f9f9;
}
::v-deep .el-table tr th {
  height: 30px;
}
::v-deep .el-table__body-wrapper tr td.el-table-fixed-column--left {
  background: none;
}
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #e1e1e1;
}
::v-deep .el-table_body tr.hover-row > td.el-table_cell {
  background: #f9f9f9;
}
::v-deep .el-table__body-wrapper tr:hover > td.el-table-fixed-column--left {
  background: #f5f7fa;
}
::v-deep .aa {
  background: #fff;
  border: 1px solid #ccc;
}
::v-deep .el-table--fit {
  border: 1px solid #ccc;
}
::v-deep .el-form-item__label {
  height: 32px;
  line-height: 32px;
}
</style>
